"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/\u5F00\u53D1\u73AF\u5883-\u524D\u7AEF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadMenus = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/\u5F00\u53D1\u73AF\u5883-\u524D\u7AEF/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _routers = _interopRequireDefault(require("./routers"));
var _store = _interopRequireDefault(require("@/store"));
var _settings = _interopRequireDefault(require("@/settings"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
var _menu = require("@/api/system/menu");
var _permission = require("@/store/modules/permission");
// progress bar
// progress bar style
// getToken from cookie

_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

_routers.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + _settings.default.title;
  }
  _nprogress.default.start();
  if ((0, _auth.getToken)()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetInfo').then(function (res) {
          // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function (err) {
          console.log(err);
          _store.default.dispatch('LogOut').then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        // 登录时未拉取 菜单，在此处拉取
      } else if (_store.default.getters.loadMenus) {
        // 修改成false，防止死循环
        _store.default.dispatch('updateLoadMenus').then(function (res) {});
        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      _nprogress.default.done();
    }
  }
});
var loadMenus = exports.loadMenus = function loadMenus(next, to) {
  (0, _menu.buildMenus)().then(function (res) {
    res.forEach(function (oneRes) {
      if (oneRes.path === '/member') {
        var customerDetail = {
          path: 'detail/:id',
          hidden: true,
          name: 'customerDetail',
          component: 'shop/user/detail',
          meta: {
            title: '客户详情',
            noCache: false
          }
        };
        oneRes.children.push(customerDetail);
        var customerEdit = {
          path: 'edit/:id',
          hidden: true,
          name: 'customerEdit',
          component: 'shop/user/form',
          meta: {
            title: '编辑客户',
            noCache: false
          }
        };
        oneRes.children.push(customerEdit);
      }
      if (oneRes.path === '/order') {
        var orderDetail = {
          path: 'detail/:id',
          hidden: true,
          name: 'orderDetail',
          component: 'shop/order/detail',
          meta: {
            title: '订单详情',
            noCache: false
          }
        };
        oneRes.children.push(orderDetail);
        var orderEdit = {
          path: 'edit/:id',
          hidden: true,
          name: 'orderEdit',
          component: 'shop/order/edit',
          meta: {
            title: '编辑订单',
            noCache: false
          }
        };
        oneRes.children.push(orderEdit);
        var orderAdd = {
          path: 'add/:id',
          hidden: true,
          name: 'orderAdd',
          component: 'shop/order/add',
          meta: {
            title: '新建订单',
            noCache: false
          }
        };
        oneRes.children.push(orderAdd);
      }
    });
    var asyncRouter = (0, _permission.filterAsyncRouter)(res);
    asyncRouter.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    _store.default.dispatch('GenerateRoutes', asyncRouter).then(function () {
      // 存储路由
      _routers.default.addRoutes(asyncRouter); // 动态添加可访问路由表
      next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
        replace: true
      }));
    });
  });
};
_routers.default.afterEach(function (to) {
  _nprogress.default.done(); // finish progress bar
});