"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/\u5F00\u53D1\u73AF\u5883-\u524D\u7AEF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/images/logo1.jpg"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '得德食小程序后台',
      logo: _logo.default
    };
  }
};