"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/\u5F00\u53D1\u73AF\u5883-\u524D\u7AEF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _axios = _interopRequireDefault(require("axios"));
var _routers = _interopRequireDefault(require("@/router/routers"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _settings = _interopRequireDefault(require("@/settings"));
// 创建axios实例
var service = _axios.default.create({
  baseURL: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : '/',
  // api 的 base_url
  timeout: _settings.default.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  var code = response.status;
  if (code < 200 || code > 300) {
    _elementUi.Notification.error({
      title: response.message
    });
    return Promise.reject('error');
  } else {
    return response.data;
  }
}, function (error) {
  var code = 0;
  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      _elementUi.Notification.error({
        title: '网络请求超时',
        duration: 5000
      });
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('LogOut').then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (code === 403) {
      _routers.default.push({
        path: '/401'
      });
    } else {
      var errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        _elementUi.Notification.error({
          title: errorMsg,
          duration: 5000
        });
      }
    }
  } else {
    _elementUi.Notification.error({
      title: '接口请求失败',
      duration: 5000
    });
  }
  return Promise.reject(error);
});
var _default = exports.default = service;